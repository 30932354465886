import { unref as _unref, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-12a692a2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["src", "alt"]

import { computed } from 'vue';
import BaseRow from "@/components/BaseRow.vue";
import BaseCol from "@/components/BaseCol.vue";



export default {
  props: {
  title: {
    type: String,
    default: "Title",
  },
  description: {
    type: String,
    default: "N/A",
  },
  md: {
    type: Number,
    default: 6,
  },
  backgroundImage: {
    type: String,
    default: "",
  },
  overlayColor: {
    type: String,
    default: "rgba(255, 255, 255, 0.3)",
  },
  revers: {
    type: Boolean,
    default: false,
  },
  image: {
    type: String,
    default: "",
  },
  childClass: {
    type: String,
    default: "bg-white rounded-2"
  }

},
  setup(__props) {

const props = __props



const containerStyle = computed(() => {
  return props.backgroundImage
      ? {
        background: `linear-gradient(93.74deg, #FFFFFF 9.11%, rgba(255, 255, 255, 0) 40.03%), url(${props.backgroundImage}) no-repeat center center/cover`,
      }
      : {};
});

const overlayStyle = computed(() => ({
  background: props.overlayColor,
}));


return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (__props.backgroundImage)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          class: "container",
          style: _normalizeStyle(_unref(containerStyle))
        }, null, 4))
      : _createCommentVNode("", true),
    _createVNode(BaseRow, {
      class: _normalizeClass(["d-flex",  __props.revers ? `flex-row-reverse ` : ``])
    }, {
      default: _withCtx(() => [
        _createVNode(BaseCol, { md: __props.md }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("h1", _hoisted_2, _toDisplayString(__props.title), 1),
              _createElementVNode("p", {
                class: "description fs-5",
                innerHTML: __props.description
              }, null, 8, _hoisted_3)
            ])
          ]),
          _: 1
        }, 8, ["md"]),
        _createVNode(BaseCol, {
          md: __props.md,
          class: "d-flex align-items-center justify-content-center"
        }, {
          default: _withCtx(() => [
            _createElementVNode("img", {
              src: __props.image,
              alt: __props.image,
              class: "image",
              style: {"width":"95%"}
            }, null, 8, _hoisted_4)
          ]),
          _: 1
        }, 8, ["md"])
      ]),
      _: 1
    }, 8, ["class"])
  ], 64))
}
}

}