import ApiService from "@/core/services/ApiService";

export const getPages = () => {
    return ApiService.get(`pages`
    );
};

export const getSinglePage = (id) => {
    return ApiService.get(`pages/${id}`);
};
