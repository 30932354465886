import ApiService from "@/core/services/ApiService";
import apiCustomEdit from "@/core/services/ApiCustomEdit";

export const getPost = (payload) => {
    return ApiService.query('posts',  {
        params: payload,
    });
};

export const getSinglePost = (id) => {
    return ApiService.get(`posts/${id}`);
};

export const AddPost = (payload) => {
    return apiCustomEdit.post('/cms/posts', payload);
}

export const updatePost = ( id,payload) => {
    return apiCustomEdit.put(`/cms/posts/${id}`, payload);
}


export const deletePost = (id) => {
    return apiCustomEdit.delete(`/cms/posts/${id}`);
}
